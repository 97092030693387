<template>
	<div>
		<div class="search_result">
			<div class="top">
				<div class="title" @click="backClick">
					{{$t('other.homePage')}}
				</div>
				<div class="img">
					<img src="../../../assets/home_icons/front.png">
				</div>
				<div class="sub_title">{{lang=='zh'?zhName:bnmName}}</div>
			</div>
			<div class="filter">
				<div class="filter_item">
					<el-cascader @change="cascaderChangeClick($event,1)" :show-all-levels="false" v-model="area_name"
						:options="countryList" clearable :placeholder="$t('other.selectCity')"></el-cascader>
				</div>
				<div class="filter_item">
					<el-cascader @change="cascaderChangeClick($event,2)" clearable v-model="cate_name"
						:options="childrenList" :placeholder="$t('other.selectType')">
					</el-cascader>
				</div>
				<div class="filter_item">
					<el-cascader @change="cascaderChangeClick($event,3)" clearable v-model="order_name"
						:options="options" :placeholder="$t('other.price')">
					</el-cascader>
				</div>
				<div class="filter_item">
					<el-cascader @change="cascaderChangeClick($event,4)" clearable v-model="time_sort_name"
						:options="options2" :placeholder="$t('dateSort')">
					</el-cascader>
				</div>
			</div>
			<div class="all_house" v-if="searchList.length>0">
				<houseItem2 v-for="(item,index) in searchList" :key="index" :houseItem2="item"></houseItem2>
			</div>
			<div class="empty" v-else>
				<el-empty :image-size=" 200" :description="$t('noData')">
				</el-empty>
			</div>
			<div class="pagination">
				<el-pagination background layout="prev, pager, next,sizes" :total="total"
					@current-change="currentChangeClick" @size-change="sizeChangeClick" :current-page="currentPage"
					:page-size="pageSize" :background="true" popper-class="select_bottom" :page-sizes="pageSizes">
				</el-pagination>
			</div>
		</div>
		<img src="../../../assets/loading.gif" class="loadingImg" v-if="loadingValue==true">
	</div>
    
	
</template>

<script>
	import houseItem2 from '@/components/houseItem2.vue';

	export default {
		components: {
			houseItem2
		},
		data() {
			return {
				countryList: [], //省市区列表
				childrenList: [], //二级分类数据
				searchList: [],
				total: 8, //总条数
				currentPage: 1, //当前页数
				pageSize: 24, //每页的条数
				pageSizes: [24],

				pName: '',
				pId: '',
				bnmName: '',
				zhName: '',
				sonId:'',

				options: [], //价格列表
				province_id: '', //省id
				province_name: '', //省名字
				addr_id: '', //市id
				addr_name: '', //市名字
				area_id: '', //区id
				area_name: '', //区名字
				cate_id: '', //分类id，
				cate_name: '', //分类名称
				order: '',
				order_name: '',
				lang: '',

				options2: [], //时间列表
				time_sort: '', //时间排序
				time_sort_name: '', //
				sort: 2, //1：按照价格排序；2：按照时间排序
				loadingValue:false,
				watchValue:'',
			}
		},
		mounted() {
			console.log('就看见')
			// console.log("llllllllll");
			// console.log(this.$route.query);
			// this.initTypeData();
		},
		created(){
			console.log("llllllllll");
			console.log('测试')
			console.log(this.$route.query);
			this.initTypeData();
			this.watchValue=this.$route.query;
		},
        // beforeRouteLeave(to, from, next){
        //     console.log(--this.currentPage)
		// if(this.currentPage==1||this.currentPage<1){
        //        next();	
		// }
		// },
		watch: {
			'$store.state.isShowFilter': function(newlang){
				console.log('业务代码')
				if(this.$store.state.isShowFilter==false){
				// console.log(this.$route.query)
				// console.log(this.watchValue)
				// console.log(this.$route.query)
				    this.area_name='';
				    this.province_id = '';
					this.addr_id = '';
					this.area_id = '';
					this.cate_name='';
					this.cate_id='';
					this.time_sort_name='';
					this.sort='';
					this.order='';
					this.order_name='';
					this.sort='';
					this.time_sort='';
				    this.initTypeData();
				}
				
				// 业务代码
			},
			// $route: {
			// 	immediate: true, // 监听到路由的变化立即执行
			// 	handler(to, from) {
			// 		// 这里是监听路由后要做的事情
			// 		console.log('这里是你监听路由后要做的事情');
			// 	},
            // },

			// console.log(this.$store.state.isShowFilter)
            //  "$route.path":{
			// 	 handler(to, from){
            //         console.log('就看看')
			// 	 }
			//  }


			// $route(to, from) {
			// 	console.log('很快就扣')
			// // 	this.initTypeData();
			// },
		},

		methods: {
			initTypeData() {
				this.sonId=this.$route.query.id;
				this.pId = this.$route.query.pId; //父id
				this.pName = this.$route.query.pName; //父名字
				this.bnmName = this.$route.query.bnmName; //西班牙语
				this.zhName = this.$route.query.zhName; //中文
				// this.currentPage=this.$route.query.pages;
				// this.cate_id = this.$route.query.cateId;
				this.lang = localStorage.getItem('lang');
				this.getSearchResult();
				this.getCountry();
				this.getChildrenCate();
				this.getPrice();
				window.addEventListener("setItemEvent", (e) => {
					if (e.key === "lang") {
						this.lang = e.newValue;
						this.getChildrenCate();
						this.getPrice();
					}
				})
			},
			backClick() {
				// this.$router.push('/home');
				this.$router.go(-1);
			},
			//获取搜索列表
			getSearchResult() {

				console.log(this.time_sort);

				let order;
				if (this.sort == 1) {
					order = this.order == 1 ? 'asc' : 'desc';
				} else {
					order = this.time_sort == 1 ? 'asc' : 'desc';
				}
                console.log(this.sonId,'看看就看看')
				let data = {
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					search_name: this.search_name,
					province_id: this.province_id,
					addr_id: this.addr_id,
					area_id: this.area_id,
					cate_id: this.cate_id,
					order: order,
					index_cate_id: this.sonId,
					sort: this.sort,
				};
				this.loadingValue=true;
				this.$http.searchResult(data).then(res => {
					this.loadingValue=false; 
					window.scrollTo(0, 0)
					if (res.code == 1) {
						this.searchList = res.data.data;
						this.total = res.data.total;
						this.$forceUpdate();
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 获取价格
			getPrice() {
				this.options = [{
						label: this.$t('other.fromBottomToTop'),
						value: 1,
					},
					{
						label: this.$t('other.fromTheHigh'),
						value: 2,
					}
				];
				this.options2 = [{
						label: this.$t('farTime'),
						value: 1,
					},
					{
						label: this.$t('lastTime'),
						value: 2,
					}
				];
			},
			// 获取国家
			getCountry() {
				this.$http.getCountry().then(res => {
					if (res.code == 1) {
						this.countryList = res.data;
						console.log(this.countryList);
						let newCountryList;
						newCountryList = this.countryList.map(item => {
							return {
								label: item.name,
								value: item.id,
								children: item.children.map(item2 => {
									return {
										label: item2.name,
										value: item2.id,
										children: item2.children.map(item3 => {
											return {
												label: item3.name,
												value: item3.id,
											}
										})
									}
								})
							}
						})
						this.countryList = JSON.parse(JSON.stringify(newCountryList));
					} else {
						this.$message.error(res.msg);
					}

				})
			},

			// 获取子分类
			getChildrenCate() {
				console.log(this.pId)
				this.$http.getChildrenCate({
					type: 2,
					pid: this.pId,
				}).then(res => {
					if (res.code == 1) {
						this.childrenList = res.data;
						let newChildrenList = this.childrenList.map(item => {
							return {
								label: item.name,
								value: item.id,
							}
						})
						this.childrenList = JSON.parse(JSON.stringify(newChildrenList));
					} else {
						this.$message.info(res.msg);
					}
				})
			},

			currentChangeClick(currentPage) {
				console.log(currentPage)
				this.currentPage = currentPage;
            //    this.$router.push({
			// 		path: '/typeResult',
			// 		query: {
			// 			pId: this.pId,
			// 			pName: this.pName,
			// 			bnmName: this.bnmName,
			// 			zhName: this.zhName,
			// 			id:this.sonId,
			// 			pages:this.currentPage,
			// 			// cateId: val.id,
			// 		}
			// 	});
				this.getSearchResult();
				// this.initTypeData();
				

			},
			sizeChangeClick(pageSize) {
				// console.log(2222)
				this.pageSize = pageSize;
				this.getSearchResult();
			},
			cascaderChangeClick(val, type) {
				console.log(val);
				console.log(type);
				switch (type * 1) {
					case 1:
						if (val.length > 0) {
							this.province_id = val[0]; //省id
							this.addr_id = val[1]; //市id
							this.area_id = val[2]; //分类id，
						} else {
							this.province_id = '';
							this.addr_id = '';
							this.area_id = '';
						}
						break;
					case 2:
						this.cate_id = val.length ? val[0] : '';
						this.sonId=val.length ? val[0] : '';
						break;
					case 3:
						this.time_sort_name = '';
						this.sort = 1;
						this.order = val.length > 0 ? val[0] : '';
						break;
					case 4:
						this.order_name = '';
						this.sort = 2;
						this.time_sort = val.length > 0 ? val[0] : '';
						break;
				}
                this.currentPage=1;
				this.getSearchResult(); 
			},

		}
	}
</script>
<style scoped="" lang="less">
	.search_result {
		width: 1200px;
		margin: 0 auto;
		margin-top: 24px;
	}

	.top {
		display: flex;
		align-items: center;
		font-size: 14px;

		.title {
			color: #999999;
		}

		.title:hover {
			cursor: pointer;
		}

		.img {
			margin: 0 8px;

			img {
				width: 8px;
				height: 8px;
			}
		}

		.sub_title {
			color: #333333;
		}
	}

	.all_house {
		margin-top: 24px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.filter {
		margin-top: 24px;
		margin-right: 12px;
		display: flex;
		font-size: 14px;
		padding: 16px 24px;
		border: 1px solid #EEEEEE;

		.filter_item {
			width: 140px;
			display: flex;
			margin-right: 72px;

			.img {
				margin-left: 5px;

				img {
					width: 8px;
					height: 4px;
				}
			}

		}
	}

	.pagination {
		margin-top: 24px;
		display: flex;
		justify-content: flex-end;
	}

	/deep/ .el-input__inner {
		border: 1px solid transparent !important;
	}
	// 新增的样式
	.loadingImg{
		width: 100rpx;
		height: 100rpx;
		position: fixed;
		top:50%;
		left: 50%;
		z-index: 11;
	}
</style>
